import React, { useEffect, useState } from "react"
import { useNavigate, NavLink, useLocation } from "react-router-dom"

import { Layout, Menu, Button, Grid } from "antd"
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
  DeploymentUnitOutlined
} from "@ant-design/icons"
import { useSelector, useDispatch } from "react-redux"

import Navigation from "../data/Navigation"
import Logo from "../assets/fitpeo_logo.png"
import { getMonitorListTypeAction } from "../redux/actions/devicesActions"
import { Mixpanel } from "../utils/mixpanel"

const { Sider } = Layout
const { useBreakpoint } = Grid

const Sidebar = props => {
  const { sidebarHidden, sidebarCollapsed, setSidebarCollapsed, setSidebarHidden } = props

  const [vitalMenu, setVitalMenu] = useState(null)

  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()
  const dispatch = useDispatch()

  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { monitorTypeList } = useSelector(state => state.monitorTypeList)

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getMonitorListTypeAction())
    }
  }, [])

  useEffect(() => {
    if (!patientMonitoringData?.rpm || !monitorTypeList?.length) return
    let showBp = false
    let showWm = false
    let showOxi = false
    let showGluco = false

    const programDevice = monitorTypeList?.find(
      ({ name }) => name.toLowerCase() === "rpm"
    )?.programs

    patientMonitoringData?.rpm?.map(({ programs }) =>
      programs?.map(({ programId, programName }) => {
        if (
          programDevice?.find(
            ({ id, name }) => id === programId && name.toLowerCase() === programName.toLowerCase()
          )
        ) {
          if (programName.toLowerCase() === "weight machine") showWm = true
          else if (programName.toLowerCase() === "blood pressure machine") showBp = true
          else if (programName.toLowerCase() === "oximeter") showOxi = true
          else if (programName.toLowerCase() === "glucose machine") showGluco = true
        }
      })
    )

    if (showBp || showWm || showOxi || showGluco) {
      setVitalMenu({
        label: "Vitals",
        key: "/vitals-group",
        icon: <DeploymentUnitOutlined />,
        children: [
          showWm && {
            label: "Weight Machine",
            key: "/vitals/weight-machine"
          },
          showBp && {
            label: "Blood Pressure",
            key: "/vitals/blood-pressure"
          },
          showOxi && {
            key: "/vitals/oximeter",
            label: "Oximeter Readings"
          },
          showGluco && {
            key: "/vitals/glucose",
            label: "Glucose Readings"
          }
        ]
      })
    }
  }, [patientMonitoringData, monitorTypeList])

  const actionIcon = () => {
    if (screens.lg === false) {
      return <CloseOutlined />
    }
    return sidebarCollapsed ? <RightOutlined /> : <LeftOutlined />
  }

  const handleActionIconClick = () => {
    if (screens.lg === false) {
      setSidebarHidden(true)
    } else {
      setSidebarCollapsed(!sidebarCollapsed)
    }
  }

  const activeURL = () => {
    const splitURL = location.pathname.split("/")
    if (splitURL.length > 2) {
      return `/${splitURL.filter(u => u.length).join("/")}`
    }
    return [location.pathname]
  }
  const getLabelByKey = key => {
    const { children } = vitalMenu
    const item = children.find(child => child.key === key)
    return item ? item.label : "device label not found"
  }
  return (
    <Sider
      theme="light"
      breakpoint="lg"
      trigger={null}
      hidden={sidebarHidden}
      collapsible={screens.lg}
      collapsed={sidebarCollapsed}
      width={225}
      collapsedWidth={screens.lg ? "60px" : 0}
      className={sidebarHidden ? "closed" : "open"}
      onCollapse={value => {
        setSidebarCollapsed(value)
      }}>
      <div className="sidebar-header">
        {sidebarCollapsed ? null : (
          <NavLink to="/">
            <img
              className="logo img-fluid ml-5"
              style={{ width: "80px", height: "auto" }}
              src={Logo}
              alt="logo"
            />
          </NavLink>
        )}
        <Button
          shape="circle"
          icon={actionIcon()}
          className="sidebar-toggler"
          onClick={handleActionIconClick}
        />
      </div>
      <Menu
        mode="inline"
        items={
          vitalMenu
            ? [...Navigation.slice(0, 2), vitalMenu, ...Navigation.slice(2, Navigation?.length)]
            : Navigation
        }
        selectedKeys={activeURL()}
        triggerSubMenuAction="click"
        onClick={({ key }) => {
          let page = key.split("/")?.[1] ? key.split("/")?.[1] : "Home"
          Mixpanel.track(
            `${
              page === "vitals" ? getLabelByKey(key) : page[0].toUpperCase() + page.slice(1)
            } Tab Clicked`
          )
          navigate(key)
        }}
      />
    </Sider>
  )
}

export default Sidebar
